import React, { useState, useEffect } from 'react';

import { getCloudinaryUrl } from './helper';

// import dateFormat from 'dateformat';


export default function Entity(props) {

  const { entity, isSelf, isExpandedMode, 
    isLoading, isSectionsView, isNameVisible, onSelect, isNeuron, isPortraitMode, isInChat, isInteractive, handleUpdateEntity } = props;

  // Handling explicitly to allow several elements to animate at once
  const [isInteracting, setIsInteracting] = useState(false);
  const handleHover = () => isInteractive ? setIsInteracting(true) : null;
  const handleLeave = () => isInteractive ? setIsInteracting(false) : null;

  // Handling explicitly to allow already available low-res picture to load first and then the high-res one
  const [isPictureLoaded, setIsPictureLoaded] = useState(false);
  const [prevPicture, setPrevPicture] = useState('');
  useEffect(() => {
    if (entity && entity.picture && entity.picture !== prevPicture) {
      setIsPictureLoaded(false);
      setPrevPicture(entity.picture);
    }
  }, [entity, prevPicture]);

  const size = entity ? 40 : Math.random() * 20; // person ? person.size : Math.random() * 20;
  const brightness = 1 // person ? person.brightness : Math.random();
  
  let x, y, translateX, translateY;
  
  if (entity) {
    
    x = entity.x;
    y = entity.y;

  } else if (isNeuron) { // For neurons, seed at the edges, and move to the middle
    
    let seed = Math.random();
    x = seed < 0.5 ? Math.random() * 100 : seed < 0.75 ? 0 : 100;
    y = seed > 0.5 ? Math.random() * 100 : seed > 0.25 ? 0 : 100;

    translateX = 50 - x;
    translateY = 50 - y;

  } else { // For stars, seed in the middle, and move to the edges
    
    x = 50 + (Math.random() * 10 - 5) * 1;
    y = 50 + (Math.random() * 10 - 5) * 1;
  
    // distance from center
    const X = Math.abs(x - 50);
    const Y = Math.abs(50 - y);

    translateX = 50 * (x > 50 ? 1 : -1) * (Y > X ? X / Y : 1) - X;
    translateY = 50 * (50 > y ? -1 : 1) * (X > Y ? Y / X : 1) - Y;
  }

  const handleClick = (event) => {
    event.stopPropagation();
    if (entity) {
      console.log(entity);
      onSelect(entity);
    }
    
    // if (entity) {
    //   if (entity.name) onSelect(entity);
    //   else onSelect(entity.id, entity.question, entity.update);
    // }
  };

  const [editingField, setEditingField] = useState(null);
  const [editedValues, setEditedValues] = useState({});

  useEffect(() => {
    setEditedValues(entity ? {
      name: entity.name || '',
      location: entity.location || '',
      bio: entity.bio || '',
      question: entity.question || '',
      update: entity.update || ''
    } : {});
  }, [entity]);

  const handleEditClick = (field) => isSelf || field === 'update' ? setEditingField(field) : null;
  const handleChange = (field, value) => setEditedValues(prev => ({ ...prev, [field]: value }));
  const handleKeyDown = (e, field) => e.key === 'Enter' && field !== 'bio' && field !== 'update' ? handleEditConfirmed(field, e) : null;
  const handleEditConfirmed = (field, e=null) => {
    setEditingField(null);
    if (e) e.preventDefault();
    if (editedValues[field] !== entity[field] && editedValues[field]) handleUpdateEntity(field, editedValues[field]);
  };

  const renderEditableField = (field, displayValue, placeholder) => {
    const isEditing = editingField === field;
    const value = editedValues[field] || '';

    if (isEditing) {
      const commonProps = {
        value: value,
        onChange: (e) => handleChange(field, e.target.value),
        onBlur: () => handleEditConfirmed(field),
        onKeyDown: (e) => handleKeyDown(e, field),
        autoFocus: true,
        className: "text-lg"
      };

      return field === 'bio' || field === 'update' 
      ? (<textarea rows={field === 'bio' ? 4 : 10} {...commonProps} />) 
      : (<input type="text" {...commonProps} />);
    }

    return (
      <div onClick={() => handleEditClick(field)} className={`whitespace-pre-line overflow-ellipsis ${isSelf || field === 'update' ? 'fade' : ''}`}>
        {(field === 'name' || field === 'question') ? (
          <>
            <span className="block text-xl font-bold">
              {displayValue.split(' ').slice(0, displayValue.split(' ').length / 2).join(' ')}
            </span>
            <span className="block m-0 w-full text-yellow-400 text-lg italic font-normal">
              {displayValue.split(' ').slice(displayValue.split(' ').length / 2).join(' ')}
            </span>
          </>
        ) : displayValue || isSelf || field === 'update' ? (
          <span className={`text-lg ${field === 'bio' ? 'text-yellow-400 italic' : ''}`}>
            {displayValue || placeholder}
          </span>
        ) : (<></>)}
      </div>
    );
  };

  return (
    <>
      {/* Normal Entity */}
      {!isExpandedMode && (
        <div
          className={`${isInChat ? 'w-full' : 'absolute mt-1.5 p-2.5'} flex ${!isPortraitMode && isInChat ? isSelf ? 'flex-row-reverse' : 'flex-row' : 'flex-col'} items-center ${isSectionsView ? 'relative w-full' : ''}`}
          style={isSectionsView || isInChat ? {} : { top: `${isPortraitMode ? x : y}%`, left: `${isPortraitMode ? y : x}%`, margin: '0.5%', transform: 'translate(-50%, -50%)' }}
        >
          
          {/* Loading animations */}
          {isLoading && (
            <div className="absolute animate-move" style={{
              '--duration': `${Math.random() * 1 + 0.33}s`,
              animationDelay: `${Math.random() * 0.5}s`,
              // dividing by 2 as it's for half the screen
              "--translateX": `${isPortraitMode ? translateY / 1 : translateX / 2}vw`,
              "--translateY": `${isPortraitMode ? translateX / 2 : translateY / 1}vh`
            }}>
              <div 
                className={`${isNeuron ? 'diamond' : 'orb'}`} 
                style={{
                  width: `${size}px`,
                  height: `${size}px`,
                  opacity: brightness
                }} 
              />
            </div>
          )}

          {/* Star */}
          {entity && entity.name && (
            <div
              onClick={handleClick} 
              onMouseEnter={handleHover}
              onMouseLeave={handleLeave}
              className={`mx-2 plain-orb ${isInteractive ? 'ease' : ''} ${isInteracting ? 'glow-interact' : ''}`}
              style={{
                width: `${size}px`, 
                height: `${size}px`, 
                opacity: brightness,
                overflow: 'hidden'
              }} 
            >
              {entity.picture && (
                <img
                  src={getCloudinaryUrl(entity.picture, size * 4, size * 4, 'g_face')}
                  alt={`Profile of ${entity.name}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}
                />
              )}
            </div>
          )}

          {isNameVisible && entity.name && (
            <div 
              onClick={handleClick} 
              onMouseEnter={handleHover}
              onMouseLeave={handleLeave}
              className={`mt-1.5 flex flex-col justify-center items-center p-1.5 rounded-lg overflow-hidden text-white text-sm text-center break-words whitespace-normal w-full max-w-[160px] ${isInteractive ? 'ease' : ''} ${isInteracting ? 'fade-interact' : ''}`}
            >
              <span className="line-clamp-2 overflow-ellipsis">{entity.name}</span>
              {entity.bio && <span className="text-yellow-400 text-xs italic line-clamp-4 overflow-ellipsis">{entity.bio}</span>}
              {entity.constellation && <span className="text-yellow-400 text-xs italic line-clamp-1 overflow-ellipsis">{entity.constellation}</span>}
            </div>
          )}

          {/* Neuron */}
          {entity && entity.question && (
            <div 
              onClick={handleClick} 
              onMouseEnter={handleHover}
              onMouseLeave={handleLeave}
              className="flex-shrink-0"
            >
              <div 
                className={`mx-2 plain-diamond ${isInteractive ? 'ease' : ''} ${isInteracting ? 'glow-interact' : ''}`}
                style={{
                  width: `${size - 10}px`, 
                  height: `${size - 10}px`, 
                  opacity: brightness
                }} 
              />
            </div>
          )}

          {isNameVisible && entity.question && (
            <div 
              onClick={handleClick} 
              onMouseEnter={handleHover}
              onMouseLeave={handleLeave}
              className={`mt-1.5 flex flex-col justify-center items-center p-1.5 rounded-lg overflow-hidden text-white text-sm text-center break-words whitespace-normal w-full max-w-[160px] ${isInteractive ? 'ease' : ''} ${isInteracting ? 'fade-interact' : ''}`}
            >
              <span className={`overflow-ellipsis ${!isPortraitMode && isInChat ? (isSelf ? 'text-right' : 'text-left') : ''}`}>{entity.question}</span>
              {entity.subscribers && (
                <span className="text-yellow-400 text-xs italic line-clamp-1 overflow-ellipsis">
                  {entity.subscribers} {entity.subscribers === 1 ? "asker" : "askers"}
                </span>
              )}
              {entity.constellation && <span className="text-yellow-400 text-xs italic line-clamp-1 overflow-ellipsis">{entity.constellation}</span>}
            </div>
          )}
        </div>
      )}

      {/* Expanded Entity */}

      {isExpandedMode && (
        <div className='flex flex-col items-center max-w-[400px] w-full'>
          
          {/* Details */}
          <div className="text-center flex flex-col justify-start after-line">
            {entity.name ? 
              renderEditableField('name', entity.name, "Add name") : 
              renderEditableField('question', entity.question, "Add question")}
          </div>

          {/* <div className="mb-4 text-center flex flex-col justify-start">
            <p onClick={entity.name ? () => { startUpdatingEntity("name"); } : () => {}} className={`text-xl font-bold text-white mb-1 overflow-ellipsis after-line ${isInteractive ? 'fade' : ''}`}>
              <span className="block">{entity.name ? entity.name.split(' ')[0] : entity.question.split(' ').slice(0, entity.question.split(' ').length / 2).join(' ')}</span>
              <span className="block m-0 w-full text-yellow-400 text-lg italic font-normal text-center">
                {entity.name ? entity.name.split(' ').slice(1).join(' ') : entity.question.split(' ').slice(entity.question.split(' ').length / 2).join(' ')}
              </span>
            </p>
            {entity.phone && <p onClick={() => { setErrorMessage("Please write to us at hi@VERSEisbetter.com to update your phone number.") }}
              className={`text-lg text-yellow-400 mb-1 italic ${isInteractive ? 'fade' : ''}`}>
              {`+${entity.phone.replace(/(\d{1,3})(\d{3})(\d{3})(\d{4})/, '$1 $2 $3 $4')}`}
            </p>}
          </div> */}

          {/* Cover & Profile picture */}
          {entity.name && (
            <div className="relative w-full aspect-square m-4">

              {/* Glow */}
              <div className="absolute inset-0 w-full h-full flex items-center justify-center">
                <div className="w-[70.6%] h-[70.6%] plain-diamond"></div>
              </div> 
              
              {/* Cover */}
              <div className="absolute inset-0 w-full h-full overflow-hidden">
                {/* {entity.cover && ( */}
                <img
                  src={getCloudinaryUrl(entity.cover || "web/default-cover", 600, 600, 'g_auto')}
                  alt="Cover"
                  className="w-full h-full object-cover"
                  style={{
                    clipPath: 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)'
                  }}
                />
                {/* )} */}
              </div>
              
              {/* Profile picture */}
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3/5 h-3/5">
                {entity.picture && (
                  <>
                    <img
                      src={getCloudinaryUrl(entity.picture, 160, 160, 'g_face')}
                      alt={`Profile of ${entity.name}`}
                      className="absolute top-0 left-0 w-full h-full object-cover plain-orb"
                    />
                    <img
                      src={getCloudinaryUrl(entity.picture, 320, 320, 'g_face')}
                      alt={`Profile of ${entity.name}`}
                      className={`absolute top-0 left-0 w-full h-full object-cover ease cursor-default plain-orb ${ // cursor-default until we allow changing the picture
                        isPictureLoaded ? 'opacity-100' : 'opacity-0'
                      }`}
                      onLoad={() => setIsPictureLoaded(true)}
                    />
                  </>
                )}
              </div>
            </div>
          )}

          {/* Details */}
          <div className="mb-8 w-full text-center relative" style={{height: !isPortraitMode ? `calc(100vh - 170px)` : 'auto'}}>
            {entity.name ? (
              <>
                {renderEditableField('location', entity.location, "Add location")}
                {renderEditableField('bio', entity.bio, "<Add bio>")}
              </>
            ) : 
              <div className={`p-4 ${!isPortraitMode ? 'absolute inset-0 overflow-auto scrollbar-hide' : ''}`}>
                {renderEditableField('update', entity.update, "<Write answer>")}
              </div>
            }
          </div>
          
          {/* <div className="mt-4 h-24 mb-8 text-center flex flex-col justify-start">
            {(entity.location || isSelf) && <p onClick={() => { startUpdatingEntity("location"); }} className={`text-xl text-white mb-1 line-clamp-1 overflow-ellipsis ${isInteractive ? 'fade' : ''}`}>
              {entity.location || "Add location"}
            </p>}
            {(entity.bio || isSelf) && (
              isEditingBio ? (
                <textarea
                  value={editedBio}
                  onChange={handleBioChange}
                  onBlur={handleBioBlur}
                  onKeyDown={handleBioKeyDown}
                  className={`text-yellow-400 italic mb-1 text-lg w-full bg-transparent border border-yellow-400 rounded p-1`}
                  rows={2}
                  autoFocus
                />
              ) : (
                <p 
                  onClick={handleBioClick} 
                  className={`text-yellow-400 italic mb-1 text-lg line-clamp-2 overflow-ellipsis ${isInteractive ? 'fade' : ''}`}
                >
                  {entity.bio || "<Add bio>"}
                </p>
              )
            )}
            {entity.question && <p onClick={() => { startUpdatingEntity(entity); }} className={`text-yellow-400 italic mb-1 text-sm whitespace-pre-line fade`}>
              {entity.update || "<Write answer>"}
            </p>}
            {!entity.bio && (
              <button onClick={() => { startUpdatingEntity("bio"); }} className="border mt-2 border-white text-white px-2 py-1 rounded inline-block w-auto">Add Bio</button>
            )}
            <p onClick={() => { startUpdatingEntity("dob"); }} className="text-yellow-400 mb-1">
              {entity.dob ? `born on ${dateFormat(Date.parse(entity.dob), "mmm d, yyyy")}` : "Add birthday"}
            </p>
          </div> */}
        </div>
      )}
    </>
  );
}