import React, { useEffect, useState } from 'react'

import './styles.css';

import Load from './Load';
import Section from "./Section";
import Entity from './Entity';
import Chat from "./Chat";
// import Sync from './Sync';

import { getIdAndOTP, 
  getCloudinaryUrl, getOptimalImageSize, 
  track, 
  getStar, patchStar, 
  getMind, getUniverse,
  getChat, postChat,
  getNeuron, postNeuron
} from './helper';

// import dateFormat from 'dateformat';

import { Helmet } from 'react-helmet';
import Modal from 'react-modal';
import { useMediaQuery } from 'react-responsive';

Modal.setAppElement('#root');


export default function App() {

  // Loading

  const [star, setStar] = useState(null);
  
  const [stars, setStars] = useState(null);
  const [filteredStars, setFilteredStars] = useState(null);
  
  const [neurons, setNeurons] = useState(null);
  const [filteredNeurons, setFilteredNeurons] = useState(null);
  
  const [isLogin, setIsLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const isPortraitMode = useMediaQuery({ orientation: 'portrait' });
  // const isMobile = useMediaQuery({ maxWidth: 767 });
  
  useEffect(() => {
    const fetchMindUniverse = async () => {
      if (getIdAndOTP().id) {  
        try {
          const [starData, starsResponse, neuronsResponse] = await Promise.all([
            getStar(),
            getUniverse(),
            getMind(),
          ]);

          setStar(starData);
          setStars(starsResponse);
          setFilteredStars(starsResponse);
          setNeurons(neuronsResponse);  
          setFilteredNeurons(neuronsResponse);

          setIsLoading(false);
          track(`Mode: ${isPortraitMode ? "Portrait" : "Landscape"}, Type: ${navigator.maxTouchPoints ? "Touch" : "Mouse"}, Screen: ${window.screen.width} x ${window.screen.height}, Device: ${navigator.userAgent}, Browser: ${navigator.appName}, OS: ${navigator.platform}`);
        } catch (error) {
          setIsLoading(false);
          track(`ERROR in loading gaze`);
          setErrorMessage("There was an error in populating your Universe. Please try again after some time.");
        }
      } else {
        setIsLogin(true);
      }
    };
    fetchMindUniverse();
  }, [isPortraitMode]);

  
  // Search

  const handleUniverseSearchChange = (event) => {
    const searchTerm = event.target.value;
    let starsToSearch = []
    if (selectedEntity && selectedEntity.question) {
      starsToSearch = askers;
    } else {
      starsToSearch = stars;
    }
    if (searchTerm) {
      const searchedStars = starsToSearch.filter((star) =>
        star.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (star.bio && star.bio.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (star.constellation ? star.constellation.toLowerCase().includes(searchTerm.toLowerCase()) : false)
      )
      setFilteredStars(searchedStars);
      track(`Searching for ${searchTerm}: ${searchedStars.length} results`)
    } else {
      setFilteredStars(starsToSearch);
      track(`Search cleared`)
      // if (!isPortraitMode) {
      //   setIsSearchVisible(false);
      // }
    }
  };

  const handleMindSearchChange = (event) => {
    const searchTerm = event.target.value;
    if (searchTerm) {
      const searchNeurons = neurons.filter((neuron) =>
        (neuron.question && neuron.question.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (neuron.update && neuron.update.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (neuron.constellation ? neuron.constellation.toLowerCase().includes(searchTerm.toLowerCase()) : false)
      )
      setFilteredNeurons(searchNeurons);
      track(`Searching for ${searchTerm}: ${searchNeurons.length} results`)
    } else {
      setFilteredNeurons(neurons);
      track(`Search cleared`)
      // if (!isPortraitMode) {
      //   setIsSearchVisible(false);
      // }
    }
  };
  

  /* Sections */

  const [expandedSection, setExpandedSection] = useState(null);
  const [isTermsAndPrivacyOpen, setIsTermsAndPrivacyOpen] = useState(false);

  const handleSectionExpand = (sectionTitle) => {
    setExpandedSection(expandedSection === sectionTitle ? null : sectionTitle);
  };  

  const toggleTermsAndPrivacy = () => {
    setIsTermsAndPrivacyOpen(!isTermsAndPrivacyOpen);
    setExpandedSection(null);
  };


  /* Entity */

  const [selectedEntity, setSelectedEntity] = useState(null);
  const [selectedStar, setSelectedStar] = useState(null);
  const [selectedNeuron, setSelectedNeuron] = useState(null);

  const selectEntity = async (entity) => {
    setSelectedEntity(entity);
    
    if (entity.name) {
      setSelectedStar(entity);
      startChat(entity);

      // setIsLoading(true);
      // const neuronsResponse = await fetchMind(env, entity.id, otp)
      // console.log(neuronsResponse)
      // setNeurons(neuronsResponse);
      // setFilteredNeurons(neuronsResponse);
      // setIsLoading(false);
    } else {
      setSelectedNeuron(entity);
      setIsLoading(true);
      setFilteredStars([]);
      const askersResponse = await getNeuron(entity.id)
      console.log(askersResponse)
      setAskers(askersResponse);
      setFilteredStars(askersResponse);
      setIsLoading(false);
      // startAnswer(entity);
    }
  };

  const deselectEntity = () => {
    track(`Deselected entity`)
    setAskers(null);
    setFilteredStars(stars);
    setSelectedEntity(null);
    setSelectedStar(null);
    setSelectedNeuron(null);
  }

  const previousEntity = () => {
    const newEntity = filteredStars[filteredStars.indexOf(selectedEntity) - 1] || filteredStars[filteredStars.length - 1];
    track(`Previous entity ${newEntity.name}`)
    selectEntity(newEntity);
  }

  const nextEntity = () => {
    const newEntity = filteredStars[filteredStars.indexOf(selectedEntity) + 1] || filteredStars[0];
    track(`Next entity ${newEntity.name}`)
    selectEntity(newEntity);
  }

  // function startUpdatingEntity(field) {
  //   const value = field === "dob" ? dateFormat(Date.parse(star.person[field]), "yyyy-mm-dd") : star.person[field];
  //   track(`Started updating ${field}: ${value}`);
  //   setUpdatingValue(value);
  //   setUpdatingField(field);
  //   setIsUpdatingEntity(true);
  // }

  const handleUpdateEntity = async (field, value, callback) => {
    setIsLoading(true);
    try {
      if (field === 'update') {
        await postNeuron(selectedNeuron.id, value);
        track(`Answered ${selectedNeuron.question}: ${value}`);
      } else {
        const response = await patchStar(field, value);
        setStar(response.data);
        track(`Updated ${field}: ${value}`);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (field === 'update') {
        track(`ERROR in answering ${selectedNeuron.question}: ${value}`);
      } else {
        track(`ERROR in updating ${field}: ${value}`);
      }
      setErrorMessage("There was an error. Please try again after some time.");
    }
  };

  // function stopUpdatingEntity() {
  //   track(`Stopped updating ${updatingField}: ${updatingValue}`);
  //   setUpdatingValue(null)
  //   setUpdatingField(null);
  //   setIsUpdatingEntity(false);
  // }


  /* Star */

  const [messages, setMessages] = useState([])
  // const [suggestion, setSuggestion] = useState('');

  // const handleChangeOrbit = async (orbit, star) => {
  //   setIsLoading(true);
  //   track(`Trying to move ${star.name} to ${orbit} orbit`);
  //   try {
  //     const response = await changeOrbit(orbit, star);
  //     setIsLoading(false);
  //     if (response.data.includes("REQUESTED")) {
  //       setErrorMessage("Since " + star.name + " is already on VERSE, they've been requested to be added to your " + orbit + " orbit. You will be notified once they approve.");
  //     } else {
  //       selectedEntity.position = orbit;
  //       setStars(await fetchUniverse());
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     track(`ERROR in moving ${star.name} to ${orbit} orbit`);
  //     setErrorMessage("There was an error in changing the orbit. Please try again after some time.");
  //   }
  // }

  const startChat = async (star) => {
    track(`Chat with entity ${star.name}`)
    setMessages([]);
    setIsLoading(true);
    try {
      const response = await getChat(star.id);
      setIsLoading(false);
      setMessages(response);
    } catch (error) {
      console.log(error)
      setIsLoading(false);
      track(`ERROR in loading chat with ${star.name}`);
      setErrorMessage("There was an error in loading the messages. Please try again after some time.");
    }
  };

  // const handleFetchSuggestion = async () => {
  //   setIsLoading(true);
  //   track(`Started suggesting to send to ${selectedEntity.name}`);
  //   try {
  //     const response = await patchChat(selectedEntity.id);
  //     setIsLoading(false);
  //     setSuggestion(response.data.suggestion);
  //   } catch (error) {
  //     setIsLoading(false);
  //     track(`ERROR in suggesting to send to ${selectedEntity.name}`);
  //     setErrorMessage("There was an error in loading the suggestion. Please try again after some time.");
  //   }
  // };

  const sendMessage = async (content) => {
    try {
      setIsLoading(true);
      await postChat(selectedEntity.id, { message: content })
      setIsLoading(false);
      const newMessage = {
        sender: 'viewer',
        content: content,
        status: 'sent',
        time: new Date().toISOString(),
      };
      setMessages([...messages, newMessage]);
      // setSuggestion('')
    } catch (error) {
      setIsLoading(false);
      track(`ERROR in sending message to ${star.name}`);
      setErrorMessage("There was an error in sending the message. Please try again after some time.");
    }
  };

  const askQuestion = async (content) => {
    try {
      setIsLoading(true);
      console.log(content)
      const newMessages = await postChat(selectedEntity.id, content);
      console.log(newMessages)
      setIsLoading(false);
      setMessages([...messages.slice(0, -2), ...newMessages.data.messages]);
      // setSuggestion('')
    } catch (error) {
      setIsLoading(false);
      console.log(error)
      track(`ERROR in sending message to ${star.name}`);
      setErrorMessage("There was an error in sending the message. Please try again after some time.");
    }
  };

  
  /* Neuron */

  const [askers, setAskers] = useState(null);
  
  // const [isWritingAnswer, setIsWritingAnswer] = useState(false);
  // const [answerRevolution, setAnswerRevolution] = useState(null);
  // const [answerQuestion, setAnswerQuestion] = useState(null);
  // const [answer, setAnswer] = useState("");

  // function startAnswer(entity) {
  //   const { id: revolution_id, question, update: answer } = entity;
  //   if (answer) track(`Started updating answer to ${question}: ${answer}`);
  //   else track(`Started writing answer to ${question}`);
  //   setAnswerRevolution(revolution_id);
  //   setAnswerQuestion(question);
  //   setAnswer(answer);
  //   setIsWritingAnswer(true);
  // }

  // const sendAnswer = async (revolution_id, text, callback) => {
  //   setIsLoading(true);
  //   try {
  //     await postNeuron(revolution_id, text);
  //     setIsLoading(false);
  //     callback();
  //     track(`Star wrote ${answerRevolution} answer: ${answer}`);
  //   } catch (error) {
  //     setIsLoading(false);
  //     track(`ERROR in star writing ${answerRevolution} answer: ${answer}`);
  //     setErrorMessage("There was an error in sending the answer. Please try again after some time.");
  //   }
  // }

  // function stopAnswer() {
  //   track(`Stopped writing answer to ${answerQuestion}: ${answer}`);
  //   setAnswer("");
  //   setAnswerRevolution(null);
  //   setAnswerQuestion(null);
  //   setIsWritingAnswer(false);
  // }


  /* Modal */

  const customModalOptionsStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 3,
      
      width: '80%',
      maxWidth: '456px',
      
      padding: '0',

      border: 'none',
      borderRadius: '10px',

      boxShadow: '0 0 30px 6px rgba(190, 227, 255, 0.65)',
      backgroundColor: 'transparent',
      color: 'white',
    },
    overlay: {
      zIndex: 2,
      backgroundColor: 'rgba(0,0,0,0.5)',
    }
  }

  /* Multiverse */

  // const chatInputRef = useRef(null);
  // const updateInputRef = useRef(null);
  // const answerInputRef = useRef(null);
  // const entityInputRef = useRef(null);
  // const searchUniverseInputRef = useRef(null);
  // const searchMindInputRef = useRef(null);
  
  // const [isSearchVisible, setIsSearchVisible] = useState(false);

  // const handleKeyDown = (event) => {
  //   if (event.target === chatInputRef.current || event.target === updateInputRef.current || event.target === entityInputRef.current) {
  //     return;
  //   }
  //   if (event.key === "Escape") {
  //     setIsSearchVisible((prevState) => !prevState);
  //     return;
  //   }
  //   if (/^[a-z0-9]$/i.test(event.key)) {
  //     setIsSearchVisible(true);
  //   }
  // };
  // const handleKeyDownMemoized = useCallback(handleKeyDown, [chatInputRef, updateInputRef, entityInputRef]);

  // useEffect(() => {
  //   document.addEventListener("keydown", handleKeyDownMemoized);
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDownMemoized);
  //   };
  // }, [handleKeyDownMemoized]);

  // useEffect(() => {
  //   if (isSearchVisible) {
  //     searchUniverseInputRef.current.focus();
  //   }
  // }, [isSearchVisible]);


  /* Orbit Update */

  // const [isWritingUpdate, setIsWritingUpdate] = useState(false);
  // const [updateFrequency, setUpdateFrequency] = useState(null);
  // const [update, setUpdate] = useState("");

  // function startUpdate(frequency) {
  //   track(`Started writing ${frequency} update`);
  //   setUpdateFrequency(frequency);
  //   setIsWritingUpdate(true);
  // }

  // const handleSendUpdate = async (frequency, text, callback) => {
  //   setIsLoading(true);
  //   try {
  //     const response = await sendUpdate(frequency, text);
  //     setStar(response.data);
  //     setIsLoading(false);
  //     callback();
  //     track(`Star wrote ${updateFrequency} update: ${update}`);
  //   } catch (error) {
  //     setIsLoading(false);
  //     track(`ERROR in star writing ${updateFrequency} update: ${update}`);
  //     setErrorMessage("There was an error in sending the update. Please try again after some time.");
  //   }
  // }

  // function stopUpdate() {
  //   track(`Stopped writing ${updateFrequency} update: ${update}`);
  //   setUpdate("");
  //   setUpdateFrequency(null);
  //   setIsWritingUpdate(false);
  // }


  /* Sync Contacts */

  // const [isSyncContactsOpen, setIsSyncContactsOpen] = useState(false);

  // function openSyncContacts() {
  //   setIsSyncContactsOpen(true);
  //   track("Opened sync contacts");
  // }
  
  // function closeSyncContacts() {
  //   setIsSyncContactsOpen(false);
  //   track("Closed sync contacts");
  // }


  // Render

  return (
    <div className="relative h-screen w-screen overflow-hidden">

      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <script src="https://accounts.google.com/gsi/client" async defer></script>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Overpass&display=swap" rel="stylesheet" />
        <title>{star && star.person.name ? star.person.name.split(' ')[0] : ""}VERSE</title>
      </Helmet>


      {/* Loading */}

      {isLoading && !isTermsAndPrivacyOpen && 
        <Load
          isData={star !== null && stars !== null && neurons !== null} 
          isLogin={isLogin} 
          isPortraitMode={isPortraitMode} 
          setIsTermsAndPrivacyOpen={setIsTermsAndPrivacyOpen} 
          selectedEntity={selectedEntity}
        />
      }


      {/* Loaded */}

      {(isTermsAndPrivacyOpen || (star && stars && neurons)) &&
        <div className="fixed top-0 left-0 h-screen w-screen flex flex-col items-center overflow-hidden">

          {/* Home */}

          {(true || isPortraitMode) &&
            <div className={`fixed inset-0 flex flex-col ${isPortraitMode ? 'overflow-y-auto overflow-x-hidden' : 'overflow-hidden'} items-stretch hide-scrollbars animate-fadeIn`}>

              {/* Scrollable container with background */}
              <div 
                className="relative flex-1 w-full flex flex-col items-stretch"
                style={{
                  backgroundImage: `url(${getCloudinaryUrl(
                    'web/enlarged-background-sharpened-darkened',
                    isPortraitMode ? getOptimalImageSize(Math.max(window.innerWidth, window.innerHeight)) * 3 : undefined,
                    isPortraitMode ? undefined : getOptimalImageSize(Math.max(window.innerWidth, window.innerHeight)), 
                    isPortraitMode ? 'a_90' : ''
                  )})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundAttachment: isPortraitMode ? 'scroll' : 'fixed',
                }}
              >

                {/* Search Bars & Terms & Privacy Header on Desktop */}
                {!isPortraitMode && (
                  <div className={`w-full ${selectedEntity || isTermsAndPrivacyOpen ? 'backdrop-blur-sm' : ''} flex flex-row justify-center items-center box-border p-2.5 pb-[18px]`}>
                  
                    {/* Search Universe */}
                    {!isTermsAndPrivacyOpen &&
                      <div className={`flex-grow p-2.5 flex justify-evenly items-center`}>
                        <span className="text-2xl">🔭</span>
                        <input type="text" placeholder={`Explore your universe`} onChange={handleUniverseSearchChange} /* ref={searchUniverseInputRef} */className="flex-grow" />
                      </div>
                    }

                    {/* Terms Header */}
                    {isTermsAndPrivacyOpen &&
                      <div className={`flex-grow p-2.5 flex justify-center items-center`}>
                        <span className="text-6xl w-full text-center" style={{ width: '400px' }}>terms of use</span>
                      </div>
                    }

                    {/* Terms & Privacy Button */}
                    <div className="flex justify-center items-center p-2.5">
                      <button onClick={selectedEntity ? deselectEntity : toggleTermsAndPrivacy} className="orb opacity-50 h-10 w-10">{isTermsAndPrivacyOpen || selectedEntity ? "x" : "i"}</button>
                    </div>

                    {/* {!isPortraitMode && (
                      <div className="absolute top-7.5 left-7.5 h-5 w-5 flex justify-center items-center border border-white rounded-full bg-transparent cursor-pointer" onClick={openSyncContacts}>
                        <img className="h-[120%] w-[120%]" src="/plus50.svg" alt="add star button" />
                      </div>
                    )} */}

                    {/* Search Mind */}
                    {!isTermsAndPrivacyOpen &&
                      <div className="flex-grow p-2.5 flex justify-evenly items-center">
                        <span className={`text-2xl ${selectedStar && !isPortraitMode ? 'opacity-0' : ''}`}>🔬</span>
                        <input type="text" placeholder={`Explore your mind`} onChange={handleMindSearchChange} /* ref={searchMindInputRef} */
                          className={`flex-grow ${selectedStar && !isPortraitMode ? 'opacity-0' : ''}`} />
                      </div>
                    }

                    {/* Privacy Header */}
                    {isTermsAndPrivacyOpen &&
                      <div className={`flex-grow p-2.5 flex justify-center items-center`}>
                        <span className="text-6xl w-full text-center" style={{ width: '400px' }}>privacy policy</span>
                      </div>
                    }
                  </div>
                )}

                {/* Search Universe & Terms Header on Mobile */}
                {isPortraitMode && (
                  <div className={`flex-grow ${selectedEntity || isTermsAndPrivacyOpen ? 'backdrop-blur-sm' : ''} p-2.5 pb-10 flex justify-evenly items-center`}>
                    
                    {/* Search Universe on Mobile */}
                    {!isTermsAndPrivacyOpen &&
                      <div className={`flex-grow flex justify-evenly items-center`}>
                        <span className="text-2xl">🔭</span>
                        <input type="text" placeholder={`Explore your universe`} onChange={handleUniverseSearchChange} /* ref={searchUniverseInputRef} */className="flex-grow" />
                      </div>
                    }

                    {/* Terms Header on Mobile */}
                    {isTermsAndPrivacyOpen &&
                      <div className={`flex-grow p-2.5 flex justify-center items-center`}>
                        <span className="text-4xl w-full text-center">terms of use</span>
                      </div>
                    }

                    {/* Terms & Privacy Button on Mobile */}
                    <div className="flex justify-center items-center">
                      <button onClick={selectedEntity ? deselectEntity : toggleTermsAndPrivacy} className="orb opacity-50 h-10 w-10">{isTermsAndPrivacyOpen || selectedEntity ? "x" : "i"}</button>
                    </div>
                  </div>
                )}  

                {/* Sections & Central Entity */}
                <div className={`${selectedEntity || isTermsAndPrivacyOpen ? 'backdrop-blur-sm' : ''} flex-1 w-full ${isPortraitMode ? 'flex flex-col' : `grid ${isTermsAndPrivacyOpen ? 'grid-cols-6' : 'grid-cols-5'} gap-4`} justify-center`}>
                  
                  {!selectedNeuron && (
                    <Section 
                      title="outer space" 
                      subtitle="meet & greet" 
                      entities={!isTermsAndPrivacyOpen ? filteredStars.filter(star => star.position === "Outer Space") : []} 
                      selectEntity={selectEntity}
                      isPortraitMode={isPortraitMode}
                      isExpanded={expandedSection === "outer space"} // || expandedSection === "profile"}
                      onExpand={handleSectionExpand}
                      isTermsAndPrivacyOpen={isTermsAndPrivacyOpen}
                      brief={"Discover new connections in your universe. Explore and engage with potential contacts, but always respect boundaries. Each star represents a unique opportunity for meaningful interaction."}
                      description={"Your universe is full of stars. Those in outer space are people who we think you should meet.\n\nEach new connection is an opportunity to build a meaningful relationship, but it's essential to respect the boundaries of others.\n\nIn this vast space, you're encouraged to explore, engage, and enlighten yourself through the beautiful minds of others.\n\nJust be mindful of your interactions—even if stars, they're people after all."} />
                  )}
                  
                  {!selectedNeuron && (
                    <Section 
                      title="orbits" 
                      subtitle="catch up & follow up" 
                      entities={!isTermsAndPrivacyOpen ? filteredStars.filter(star => star.position !== "Outer Space") : []} 
                      selectEntity={selectEntity}
                      isPortraitMode={isPortraitMode}
                      isExpanded={expandedSection === "orbits"} // || expandedSection === "persona"}
                      onExpand={handleSectionExpand}
                      isTermsAndPrivacyOpen={isTermsAndPrivacyOpen}
                      brief={"Nurture your existing relationships. These connections thrive on consistent, respectful interactions. Your orbits represent the bonds of trust and mutual respect you've built over time."}
                      description={"Your orbits are where your relationships take on life.\n\nMaintaining these connections is not just about the past; it's about building a future together.\n\nAs you catch up and follow up, be mindful of the consistency and integrity in your interactions.\n\nThese orbits are held together by the gravitational pull of trust and mutual respect, and your actions should contribute to keeping these bonds strong."} />
                  )}

                  {!isTermsAndPrivacyOpen && selectedNeuron && (
                    <div className="col-span-2">
                      <Section 
                        title="askers" 
                        subtitle="everyone who asked" 
                        entities={filteredStars} 
                        selectEntity={selectEntity}
                        isPortraitMode={isPortraitMode}
                        isExpanded={expandedSection === "askers"} // || expandedSection === "persona"}
                        onExpand={handleSectionExpand}
                        brief={"Everyone who asked you a question."}
                        description={"Everyone who asked you a question. And will receive your answer when you sent it."}
                      />
                    </div>
                  )}
                  
                  {isTermsAndPrivacyOpen && (
                    <Section 
                      title="our star" 
                      subtitle="think & ink"
                      entities={[]}
                      isPortraitMode={isPortraitMode}
                      isExpanded={expandedSection === "our star"} // || expandedSection === "private"}
                      onExpand={handleSectionExpand}
                      isTermsAndPrivacyOpen={isTermsAndPrivacyOpen}
                      brief={"Your personal space for thoughts and ideas. Reflect, set goals, and shape your journey. By sharing your aspirations, you help us tailor your experience while maintaining full privacy control."}
                      description={"At the center of your universe is our star—your thoughts, reflections, goals, and ideas.\n\nIt's your space to ink down the words that guide your journey, to think deeply and create meaning in your stellar system.\n\nInking your thoughts is a powerful tool on its own, however it comes with an added advantage:\n\nWith you in complete control of your privacy, you can express your goals to us, and help us shape the path ahead."}
                    />
                  )}
                  
                  {/* Central Entity */}
                  {!isTermsAndPrivacyOpen && (
                    <div className="flex justify-center items-start min-w-0">
                      <Entity 
                        entity={selectedEntity ? selectedEntity : star.person}
                        isSelf={selectedEntity ? false : true}
                        isExpandedMode={true}
                        handleUpdateEntity={handleUpdateEntity}
                        isPortraitMode={isPortraitMode}
                      />
                    </div>
                  )}

                  {/* Privacy Header on Mobile */}
                  {isPortraitMode && isTermsAndPrivacyOpen && (
                    <div className="flex-grow p-2.5 pb-10 flex justify-evenly items-center">

                      <div className={`flex-grow p-2.5 flex justify-center items-center`}>
                        <span className="text-4xl w-full text-center">privacy policy</span>
                      </div>

                      <div className="flex justify-center items-center">
                        <button onClick={toggleTermsAndPrivacy} className="orb opacity-50 h-10 w-10">{isTermsAndPrivacyOpen ? "x" : "i"}</button>
                      </div>
                    </div>
                  )}

                  {isTermsAndPrivacyOpen && (
                    <Section 
                      title="profile" 
                      subtitle="available publicly"
                      entities={[]}
                      isPortraitMode={isPortraitMode}
                      isExpanded={expandedSection === "profile"} // || expandedSection === "outer space"}
                      onExpand={handleSectionExpand}
                      isTermsAndPrivacyOpen={isTermsAndPrivacyOpen}
                      brief={"Your public face in the network. This information is freely accessible, allowing seamless connections. Remember, you control how you're presented to the world."}
                      description={"Your mind is made up of neurons. Your profile is the first information in this neural network.\n\nIt is shared openly with others, allowing them to connect seamlessly with you without any friction.\n\nAs it is available freely for all to see, remember that its public nature means anyone can access it without asking.\n\nThis openness fosters connection and discovery, but you always hold the power to shape how you're seen."} 
                    />
                  )}

                  {(!selectedStar || isPortraitMode) && <Section 
                    title="persona" 
                    subtitle="answers promptly" 
                    entities={!isTermsAndPrivacyOpen ? filteredNeurons.filter(neuron => neuron.update !== null) : []} 
                    selectEntity={selectEntity}
                    isPortraitMode={isPortraitMode}
                    isExpanded={expandedSection === "persona"} // || expandedSection === "orbits"}
                    onExpand={handleSectionExpand}
                    isTermsAndPrivacyOpen={isTermsAndPrivacyOpen}
                    brief={"Your efficient, automated responses. Engage with others without constant attention. You're notified of interactions and retain full control over your shared information."}
                    description={"Your persona is built for efficiency, letting you share parts of your mind automatically but only when asked.\n\nIt allows you to engage with interested people without spending time.\n\nPrivacy is key—you're notified when others view this information, and you can always delete it, either partly or entirely.\n\nThis balance ensures your persona remains responsive while still giving visibility and control over your data."} />}
                  
                  {(!selectedStar || isPortraitMode) && <Section 
                    title="private"
                    subtitle="address personally" 
                    entities={!isTermsAndPrivacyOpen ? filteredNeurons.filter(neuron => neuron.update === null) : []}
                    selectEntity={selectEntity}
                    isPortraitMode={isPortraitMode}
                    isExpanded={expandedSection === "private"} // || expandedSection === "our star"}
                    onExpand={handleSectionExpand}
                    isTermsAndPrivacyOpen={isTermsAndPrivacyOpen}
                    brief={"Your secure, personal information. Stored locally for privacy, yet accessible for quick responses. You decide when and with whom to share, balancing efficiency and confidentiality."}
                    description={"Your private information is stored securely with you and with those you share it with.\n\nThese are answers to personal questions, stored locally (and not with us).\n\nStill, this local cache lets you respond efficiently, allowing you to reuse answers but only after reviewing who asked.\n\nThis balance between privacy and efficiency empowers you to engage privately without sacrificing seamless communication."} />}
                    
                </div>

                {/* Search Mind on Mobile */}
                {isPortraitMode && (
                  <div className={`${selectedEntity || isTermsAndPrivacyOpen ? 'backdrop-blur-sm' : ''} flex-grow p-2.5 flex justify-evenly items-center`}>
                    
                    {/* Search Mind */}
                    {!isTermsAndPrivacyOpen &&
                      <div className="flex-grow flex justify-evenly items-center">
                        <span className="text-2xl">🔬</span>
                        <input type="text" placeholder={`Explore your mind`} onChange={handleMindSearchChange} /* ref={searchMindInputRef} */ className="flex-grow" />
                      </div>
                    }

                  </div>
                )}

              </div>
            </div>
          }


          {/* Entity */}

          {/* <Modal
            isOpen={isUpdatingEntity}
            onRequestClose={stopUpdatingEntity}
            style={customModalOptionsStyles}
            contentLabel="Update User"
            shouldCloseOnOverlayClick={true}
          >
            <div className="w-full max-w-[456px] flex flex-col items-center box-border p-5 bg-[rgba(0,0,0,0.8)]">
              <p className="mb-2.5 text-white text-2xl text-center">Update {updatingField}</p>
              {updatingField === "bio"
                ? <textarea maxLength={75} ref={entityInputRef} value={updatingValue} onChange={e => setUpdatingValue(e.target.value)} />
                : <input ref={entityInputRef} type="text" value={updatingValue} onChange={e => setUpdatingValue(e.target.value)} />
              }
              <button onClick={() => { handleUpdateEntity(updatingField, updatingValue, stopUpdatingEntity) }}>Update</button>
            </div>
          </Modal> */}


          {/* Star */}

          {selectedStar && (
            <div className={`${isPortraitMode ? 'fixed inset-0' : 'absolute top-0 right-0 w-2/5 h-full'}`}>
              <Chat
                // inputRef={chatInputRef}
                selectedEntity={selectedStar}
                // changeOrbit={handleChangeOrbit}
                onPrevious={previousEntity}
                onNext={nextEntity}
                onClose={deselectEntity}
                messages={messages}
                // suggestion={suggestion}
                // fetchSuggestion={handleFetchSuggestion}
                onSendMessage={sendMessage}
                onAskQuestion={askQuestion}
                isPortraitMode={isPortraitMode}
                onSelect={selectEntity}
              />
            </div>
          )}


          {/* Neuron */}

          {/* <Modal
            isOpen={isWritingAnswer}
            onRequestClose={stopAnswer}
            style={customModalOptionsStyles}
            contentLabel="Write Answer"
            shouldCloseOnOverlayClick={true}
          >
            <div className="w-full max-w-[456px] flex flex-col items-center box-border p-5 bg-[rgba(0,0,0,0.8)]">
              <p className="mb-2.5 text-white text-2xl text-center">{answerQuestion}</p>
              <textarea ref={answerInputRef} value={answer} onChange={e => setAnswer(e.target.value)} className="min-h-[160px]"></textarea>
              <button onClick={() => { sendAnswer(answerRevolution, answer, stopAnswer) }}>Send</button>
            </div>
          </Modal> */}


          {/* Multiverse */}

          {/* {(false && !isPortraitMode) &&
            <div className="relative h-screen w-full">

              Sky with Stars
              <div>
                {filteredStars.slice(0, 500).map((star, i) => {
                  return <Entity key={`${star.id}-${i}`} entity={star} onSelect={startChat} isNameVisible={i <= 50 ? true : false} />;
                })}
                
                <div className="absolute top-7.5 left-7.5 h-5 w-5 flex justify-center items-center border border-white rounded-full bg-transparent cursor-pointer" onClick={openSyncContacts}>
                  <img className="h-[120%] w-[120%]" src="/plus50.svg" alt="add star button" />
                </div>
              </div>

              Search
              {isSearchVisible && <div className="absolute top-1/2 left-1/2 z-10 w-full max-w-[500px] flex justify-evenly items-center transform -translate-x-1/2 -translate-y-1/2">
                <span>🔭</span>
                <input
                  type="text"
                  placeholder={`Search in ${star.star.name.split(' ')[0] ?? ""}VERSE`}
                  onChange={handleUniverseSearchChange}
                  onInput={() => setIsSearchVisible(true)}
                  ref={searchUniverseInputRef}
                  className="ml-2.5 w-full"
                />
              </div>}

            </div>} */
          }


          {/* Orbit Update */}

          {/* <Modal
            isOpen={isWritingUpdate}
            onRequestClose={stopUpdate}
            style={customModalOptionsStyles}
            contentLabel="Write Update"
            shouldCloseOnOverlayClick={true}
          >
            <div className="w-full max-w-[456px] flex flex-col items-center box-border p-5 bg-[rgba(0,0,0,0.8)]">
              <p className="mb-2.5 text-white text-2xl text-center">Write your {updateFrequency} update</p>
              <textarea ref={updateInputRef} value={update} onChange={e => setUpdate(e.target.value)} className="min-h-[160px]"></textarea>
              <button onClick={() => { handleSendUpdate(updateFrequency, update, stopUpdate) }}>Send</button>
            </div>
          </Modal> */}


          {/* Sync Contacts */}

          {/* <Modal
            isOpen={isSyncContactsOpen}
            onRequestClose={closeSyncContacts}
            style={customModalOptionsStyles}
            contentLabel="Login to Add People"
            shouldCloseOnOverlayClick={true}
          >
            <Sync />
          </Modal> */}

        </div>
      }


      {/* Error */}

      <Modal
        isOpen={errorMessage.length > 0}
        onRequestClose={() => { setErrorMessage('') }}
        style={customModalOptionsStyles}
        contentLabel="Error Message"
        shouldCloseOnOverlayClick={true}
      >
        <div className="w-full max-w-[456px] flex flex-col items-center box-border p-5 bg-[rgba(0,0,0,0.8)]">
          <p className="mb-2.5 text-white text-2xl text-center">{errorMessage}</p>
        </div>
      </Modal>

    </div>
  );
}