import axios from "axios";


// Environment variables

export const getEnv = () => process.env.REACT_APP_DATA_ENV;

export const getIdAndOTP = (forURLSeparator = false) => {
  const hardcodedId = process.env.REACT_APP_HARDCODED_ID;
  const id = hardcodedId || new URLSearchParams(window.location.search).get('id');
  const otp = hardcodedId ? 'fixsocialmedia' : new URLSearchParams(window.location.search).get('otp');
  return forURLSeparator ? `${id}/${otp}` : { id, otp };
}


// Image processing

export const getCloudinaryUrl = (publicId, h, w, t) =>
  `https://res.cloudinary.com/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/f_auto,q_auto,c_fill${t ? `,${t}` : ``}${h ? `,h_${h}` : ``}${w ? `,w_${w}` : ``}/${getEnv() === "dev" && t === 'g_face' ? `samples/look-up` : publicId}`;

export const getOptimalImageSize = (dimension) => {
  const commonSizes = [640, 828, 1080, 1792, 1920, 2048, 2560, 3840]; // 750, 1200 excluded, 1792 included
  return commonSizes.find(size => size >= dimension) || commonSizes[commonSizes.length - 1];
};


// API requests

const getBaseUrl = () => getEnv() === "dev" ? "http://127.0.0.1:5000" : `https://verse-${getEnv()}.herokuapp.com`;

export const track = async (event) => await axios.post(`${getBaseUrl()}/track/${getIdAndOTP(true)}`, { track: event });


/* Star */

export const getStar = async () => (await axios.get(`${getBaseUrl()}/star/${getIdAndOTP(true)}`)).data;

export const patchStar = async (field, value) => await axios.patch(`${getBaseUrl()}/star/${getIdAndOTP(true)}`, { person: { [field]: value } });

// export const sendUpdate = async (frequency, text) => await axios.patch(`${getBaseUrl()}/star/${getIdAndOTP(true)}`, { [{ yearly: 'year', quarterly: 'quarter', monthly: 'month', weekly: 'week', daily: 'day' }[frequency]]: { update: text } });


/* Gaze & Mind */

export const getUniverse = async () => {
  const response = await axios.get(`${getBaseUrl()}/gaze/${getIdAndOTP(true)}`);
  return [
    ...response.data.daily.flatMap((element) =>
      element.people.map((person) => ({ ...person, constellation: element.constellation ? element.constellation.name : null, position: "Daily", size: Math.random() * 3 + 15, brightness: 1, x: Math.random() * 97.5 + 1.25, y: Math.random() * 92.5 + 2.5 }))
    ),
    ...response.data.weekly.flatMap((element) =>
      element.people.map((person) => ({ ...person, constellation: element.constellation ? element.constellation.name : null, position: "Weekly", size: Math.random() * 3 + 12, brightness: Math.random() * 0.25 + 0.8, x: Math.random() * 97.5 + 1.25, y: Math.random() * 92.5 + 2.5 }))
    ),
    ...response.data.monthly.flatMap((element) =>
      element.people.map((person) => ({ ...person, constellation: element.constellation ? element.constellation.name : null, position: "Monthly", size: Math.random() * 3 + 9, brightness: Math.random() * 0.25 + 0.6, x: Math.random() * 97.5 + 1.25, y: Math.random() * 92.5 + 2.5 }))
    ),
    ...response.data.quarterly.flatMap((element) =>
      element.people.map((person) => ({ ...person, constellation: element.constellation ? element.constellation.name : null, position: "Quarterly", size: Math.random() * 3 + 6, brightness: Math.random() * 0.25 + 0.4, x: Math.random() * 97.5 + 1.25, y: Math.random() * 92.5 + 2.5 }))
    ),
    ...response.data.yearly.flatMap((element) =>
      element.people.map((person) => ({ ...person, constellation: element.constellation ? element.constellation.name : null, position: "Yearly", size: Math.random() * 3 + 3, brightness: Math.random() * 0.25 + 0.3, x: Math.random() * 97.5 + 1.25, y: Math.random() * 92.5 + 2.5 }))
    ),
    ...response.data.outer_space.flatMap((element) =>
      element.people.map((person) => ({ ...person, constellation: element.constellation ? element.constellation.name : null, position: "Outer Space", size: Math.random() * 3 + 2, brightness: Math.random() * 0.25 + 0.25, x: Math.random() * 97.5 + 1.25, y: Math.random() * 92.5 + 2.5 }))
    )
  ]
};

// export const changeOrbit = async (orbit, star) => await axios.patch(`${getBaseUrl()}/gaze/${getIdAndOTP(true)}`, { [orbit]: [{ people: [{ id: star.id }] }] });

export const getMind = async () => (await axios.get(`${getBaseUrl()}/mind/${getIdAndOTP(true)}`)).data.revolutions.sort((a, b) => b.subscribers - a.subscribers);


/* Chat */

export const getChat = async (starId) => (await axios.get(`${getBaseUrl()}/chat/${getIdAndOTP(true)}/${starId}`)).data.messages;

export const postChat = async (starId, content) => await axios.post(`${getBaseUrl()}/chat/${getIdAndOTP(true)}/${starId}`, content);

// export const patchChat = async (selectedEntityId) => await axios.patch(`${getBaseUrl()}/chat/${getIdAndOTP(true)}/${selectedEntityId}`);


/* Neuron */

export const getNeuron = async (revolution_id) => (await axios.get(`${getBaseUrl()}/neuron/${getIdAndOTP(true)}/${revolution_id}`)).data.askers;

export const postNeuron = async (revolution_id, text) => await axios.post(`${getBaseUrl()}/neuron/${getIdAndOTP(true)}/${revolution_id}`, { "update": text });