import React, { useEffect, useRef, useState } from "react";

import Entity from './Entity';

import { getCloudinaryUrl } from './helper';

import { format, isToday, isYesterday, isSameDay, isSameYear } from 'date-fns';

export default function Chat (props) {

  const { selectedEntity, 
    // inputRef, changeOrbit, suggestion, fetchSuggestion
    onPrevious, onNext, onClose, onSelect,
    messages, onSendMessage, onAskQuestion, isPortraitMode } = props;

  const [inputValue, setInputValue] = useState('');
  const textareaRef = useRef(null);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      const newHeight = Math.min(textarea.scrollHeight, 5 * 24); // Assuming 24px per line
      textarea.style.height = `${newHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [inputValue]);

  const lastMessageRef = useRef(null);

  // const handleOrbitChange = (event) => {
  //   const orbit = event.target.value;
  //   changeOrbit(orbit, selectedEntity);
  // };

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    // setInputValue(suggestion);
  }, [messages]); // suggestion removed

  const renderDateSeparator = (date) => (
    <div className="flex items-center justify-center my-4">
      <div className="bg-gray-700 text-gray-300 text-xs px-2 py-1 rounded-full">
        {isToday(date) ? 'Today' : 
         isYesterday(date) ? 'Yesterday' : 
        //  isSameWeek(date, new Date()) ? format(date, 'EEE') :
         isSameYear(date, new Date()) ? format(date, 'EEE, MMM d') :
         format(date, 'EEE, MMM d, yyyy')} 
      </div>
    </div>
  );

  return (
    <div className={`absolute inset-0 ${isPortraitMode ? 'bg-[rgba(0,0,0,0.5)] backdrop-blur-sm' : 'bg-transparent'}`}>
      
      {/* Header */}
      {isPortraitMode && (
        <div className="relative w-full text-white bg-[rgba(0,0,0,0.3)] backdrop-blur-sm">

          <div className="min-h-[60px] w-full flex justify-between items-center px-5">
            
            {/* <div className="min-w-[200px] flex items-center">
              <div className="flex-grow flex-shrink max-h-[2.4em] overflow-hidden line-clamp-2"> */}
            <div className="flex items-center space-x-3">
              <div className="w-10 h-10 orb flex items-center justify-center">
                {selectedEntity.picture ? (
                  <img
                    src={getCloudinaryUrl(selectedEntity.picture, 160, 160, 'g_face')}
                    alt={`Profile of ${selectedEntity.name}`}
                    className="w-full h-full object-cover rounded-full"
                  />
                ) : ('')
                  // <span className="text-[#61dafb]">{selectedEntity.name.charAt(0)}</span>
                }
              </div>
              <div className="font-semibold truncate max-w-[150px]">
                {selectedEntity.name}
              </div>
              {/* <select
                className="mx-2.5 px-1.5 py-1 border border-[rgba(85,85,85,0.8)] rounded bg-[rgba(51,51,51,0.8)] text-white font-inherit text-base transition-colors duration-300 ease-in-out cursor-pointer hover:bg-[rgba(51,51,51,0.6)]"
                value={selectedEntity.position}
                onChange={handleOrbitChange}
              >
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
                <option value="Quarterly">Quarterly</option>
                <option value="Yearly">Yearly</option>
                <option value="Outer Space">Outer Space</option>
              </select> */}
            </div>
            <div className="flex items-center space-x-2">
              <div onClick={onPrevious} className="p-2 border-none fade bg-transparent">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <polyline points="15 18 9 12 15 6"></polyline>
                </svg>
              </div>
              <div onClick={onNext} className="p-2 border-none fade bg-transparent">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <polyline points="9 18 15 12 9 6"></polyline>
                </svg>
              </div>
              <div onClick={onClose} className="p-2 border-none fade bg-transparent">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </div>
              
            </div>

          </div>

          {/* Gradient border */}
          <div className="line"></div>

        </div>
      )}

      {/* Body */}
      <div 
        style={{ 
          // height: `calc(100% - ${isPortraitMode ? '60px' : '0px'} - 1px - 20px - ${textareaRef.current ? textareaRef.current.style.height : '0px'})`,
          maxHeight: isPortraitMode ? 'calc(100% - 60px)' : '100%'
        }} 
        className="flex flex-col p-4 overflow-y-auto custom-scrollbar"
      >
        {messages.map((message, index) => {
          const isSelf = message.sender !== selectedEntity.id;
          const isLastMessage = index === messages.length - 1;
          const showDateSeparator = message.time ? (index === 0 || !isSameDay(new Date(message.time), new Date(messages[index - 1].time))) : false;
          
          return (
            <React.Fragment key={index}>
              {showDateSeparator && renderDateSeparator(new Date(message.time))}
              {!message.content && (index === 0 || messages[index - 1].content) && (
                <div className="flex items-center justify-center my-4">
                  <div className="text-gray-300 text-center italic px-2 py-1 rounded-full">
                    {message.time ? `Waiting for ${selectedEntity.name.split(' ')[0]} to respond to:` : `Consider asking any of the following from ${selectedEntity.name.split(' ')[0]}:`}
                  </div>
                </div>
              )}
              <div
                className={`flex ${isSelf ? 'flex-row-reverse' : (message.content ? 'flex-row' : 'justify-center')} items-center ${index === messages.length - 1 ? 'mb-20' : 'mb-4'} gap-2`}
                ref={isLastMessage ? lastMessageRef : null}
              >
                {message.content && (
                  <div className={`max-w-[70%] rounded-lg px-4 py-2 shadow-md overflow-hidden
                    ${!isSelf ? 'background-gradient-1' : 'background-gradient-2'}`}
                  >
                    <p className={`text-white text-sm whitespace-pre-wrap break-words overflow-x-auto
                      ${!isSelf ? 'foreground-gradient-1' : 'foreground-gradient-2'}`}
                    >
                      {message.content}
                    </p>
                    <div className="flex justify-end items-center mt-1">
                      <span className="text-xs text-gray-300">{format(new Date(message.time), 'HH:mm')}</span>
                      {/* {message.status && (
                        <span className="ml-1 text-xs text-gray-400">
                        {message.status === "sent" && "✓"}
                        {message.status === "delivered" && "✓✓"}
                        {message.status === "read" && "✓✓"}
                        </span>
                        )} */}
                      {message.status && isSelf && (
                        <svg xmlns="http://www.w3.org/2000/svg" className="ml-1" width="14" height="14" viewBox="0 0 24 24" fill="none"
                          strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                          stroke={message.status === "read" ? "green" : "gray"}
                        >
                          <polyline points="20 6 9 17 4 12" />
                          {(message.status === "delivered" || message.status === "read") && (
                            <polyline points="25 6 14 17 9 12" />
                          )}
                        </svg>
                      )}
                    </div>
                  </div>
                )}
                {message.question && (
                  <div className={`${message.content ? 'flex-grow' : ''}`}>
                    <Entity 
                      entity={{...message, id: message.revolution_id}} // id is redundant and needs to be removed after addressing redundancy
                      isNameVisible={true}
                      isInChat={true}
                      isSelf={isSelf}
                      onSelect={isSelf ? onSelect : (message.time ? () => {} : onAskQuestion)}
                      isInteractive={isSelf || !message.time}
                      isPortraitMode={isPortraitMode}
                    />
                  </div>
                )}
              </div>
            </React.Fragment>
          );
        })}
      </div>

      {/* Footer */}
      <div className={`absolute bottom-0 left-0 w-full flex flex-col justify-end items-center box-border text-white backdrop-blur-sm
        ${isPortraitMode ? 'translucent' : 'translucent-gradient'}`}>
        
        {/* Gradient border */}
        <div className="line"></div>
        
        <div className="w-full flex flex-grow items-center box-border p-2.5">
          
          {/* <div onClick={fetchSuggestion} className="p-2 bg-[#162d2f] rounded-full hover:opacity-80 transition-colors">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="16" x2="12" y2="12"></line>
              <line x1="12" y1="8" x2="12.01" y2="8"></line>
            </svg>
          </div> */}

          {/* <div className="flex-grow flex items-center"> */}
          <textarea
            ref={textareaRef}
            rows="1"
            placeholder="Type your message"
            value={inputValue}
            maxLength={900}
            onChange={(e) => setInputValue(e.target.value)}
            className="flex-grow"
          />
          {/* </div> */}
          {/* <div 
            onClick={() => {if (inputValue) {onSendMessage(inputValue); setInputValue('');}}} 
            className="flex justify-center items-center fade"
          > */}
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <line x1="22" y1="2" x2="11" y2="13"></line>
              <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
            </svg> */}
          <span onClick={() => {if (inputValue) {onSendMessage(inputValue); setInputValue('');}}} className="text-2xl fade">🚀</span>
          {/* </div> */}
          
        </div>
      </div>
    </div>
  );
};