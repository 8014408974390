import React from 'react';
import Entity from './Entity';
import { FixedSizeList as List } from 'react-window';

export default function Section({ 
  entities, 
  title, 
  subtitle, 
  selectEntity, 
  update, 
  writeUpdate, 
  isPortraitMode,
  isTermsAndPrivacyOpen,
  brief,
  description,
  isExpanded,
  onExpand
}) {

  const Item = ({ index, style }) => {
    const entity = entities[index];
    return (
      <div style={{...style, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', overflow: isPortraitMode ? 'hidden' : 'visible'}}>
        <Entity
          key={index}
          entity={entity}
          onSelect={selectEntity}
          isInteractive={true}
          isSectionsView={true}
          isNameVisible={true}
        />
      </div>
    );
  };

  const toggleExpansion = () => {
    onExpand(title);
  };

  return (
    <div className="min-h-[100px] w-full">
      <p className={`m-0 w-full text-xl font-bold text-center
        ${!isPortraitMode && !isTermsAndPrivacyOpen ? 'fade' : ''}`}
        onClick={isPortraitMode ? toggleExpansion : undefined}
        onMouseEnter={!isPortraitMode ? toggleExpansion : undefined}
        onMouseLeave={!isPortraitMode ? toggleExpansion : undefined}
      >
        {title}
        {!isTermsAndPrivacyOpen && entities && entities.length ? ` (${entities.length})` : ''}
        {!isPortraitMode ? '' : isExpanded ? '\t\u25BC\uFE0E' : '\t\u25B6\uFE0E'}
      </p>
      <p className="m-0 w-full text-yellow-400 text-lg italic font-normal text-center after-line">
        {subtitle}
      </p>
      {!isTermsAndPrivacyOpen && !isExpanded  && entities && entities.length > 0 && (
        <div className="h-full w-full flex flex-col justify-start items-center">
          {/* {writeUpdate && (
            <div className="update-container" onClick={() => { writeUpdate(frequency) }}>
              {update ? (
                <p className="update-text">{update.length > 280 ? `${update.substring(0, 280)} ...` : update}</p>
              ) : (
                <button>Write Update</button>
              )}
            </div>
          )} */}
          {/* <div className={`h-full w-full flex justify-center ${isPortraitMode ? 'overflow-x-auto' : ''}`}> */}
          <List
            className={`inline-flex ${isPortraitMode ? 'flex-row' : 'flex-col'} overflow-auto whitespace-nowrap scrollbar-hide`}
            width={isPortraitMode ? window.innerWidth : '100%'}
            height={isPortraitMode ? 204 : window.innerHeight - 170}
            itemCount={entities.length}
            itemSize={160}
            layout={isPortraitMode ? 'horizontal' : 'vertical'}>
            {Item}
          </List>
          {/* </div> */}
        </div>
      )}
      {(isTermsAndPrivacyOpen || isExpanded) && (
        <div className="mb-4 min-h-[188px] relative" style={{height: !isPortraitMode ? `calc(100% - 62px)` : 'auto'}}>
          <div className={`p-4 ${!isPortraitMode ? 'absolute inset-0 overflow-auto scrollbar-hide' : ''}`}>
            <p className="text-white text-lg text-center whitespace-pre-line">
              {isPortraitMode 
                ? (isExpanded 
                  ? (isTermsAndPrivacyOpen ? description : brief) 
                  : brief) 
                : description}
            </p>
          </div>
        </div>
      )}
      {!isTermsAndPrivacyOpen && !isExpanded && (!entities || entities.length === 0) && 
        <div className="flex items-center justify-center" style={{ height: isPortraitMode ? '204px' : 'auto' }}>
          <p className="p-10 text-white text-sm opacity-50 text-center">There's nothing here, so far.</p>
        </div>
      }
    </div>
  );
};
