/* Entry point for the React app */

import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';

import { GoogleOAuthProvider } from '@react-oauth/google';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      {/* <Router>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route path="/app" component={App} />
        </Switch>
      </Router> */}
      <App />
    </GoogleOAuthProvider>
  </React.StrictMode>
);